/* Globle Css Part */

body {
  margin: 0;
  padding: 0;
  font-family: "Alliance No.1" !important;
  box-sizing: border-box;
  background-image: none;
  overflow-x: hidden;
}
.container {
  max-width: 1200px !important;
  margin: 0 auto;
  padding: 0 16px !important;
}
.h1,
.h2,
.h3,
p,
.p-small {
  font-family: "Alliance No.1";
}
a {
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}
.h1-banner {
  font-size: 56px !important;
  background: #032F70;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Alliance No.1";
  margin: 0;
  font-weight: 800 !important;
  margin: 0 !important;
}
.h2 {
  font-size: 64px !important;
  font-weight: 700 !important;
  color: #00265E !important;
}
.h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  color: #00265E !important;
}
.h4 {
  font-size: 24px !important;
  font-weight: 700 !important;
}
p {
  font-size: 18px !important;
}
.p-small {
  font-size: 16px !important;
}
/* Globle Css Part End */
.banner-section .banner-left .span {
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
  color: #333333;
  display: inline-block;
  font-family: "Alliance No.1";
  padding: 10px 0 20px 0;
}

.banner-section .banner-image img {
  width: 100%;
  height: auto;
}

.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top:0px;
  height: 100vh;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  background-color: #fff;
}
.banner-section .container{
  display: flex;
  align-items: center;
  gap: 30px;
}
.banner-section .banner-left {
  width: 50%;
}
.banner-section .banner-image {
  width: 50%;
}
.upload-btn {
  outline: 0;
  border-radius: 8px;
  padding: 20px 25px;
  border-radius: 8px;
  background: #032F70;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;
  margin-top: 32px;
  transition: all ease-in-out 0.3s;
}
.upload-btn:hover{
  transform: translateY(-5px);
}
.upload-btn .upload-text {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #fff !important;
  transition: all 0.3s ease-out 0s !important;
  top: 0 !important;
  width: 100% !important;
  position: fixed !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  font-size: 1.2rem !important;
  border-bottom: 1px solid #D1D1D1;
  background: #fff;
}
.navbar{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #000 !important;
  height: 2px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}


.navbar-brand {
  color: rgb(7, 7, 7) !important;
  margin: 0 !important;
  padding: 0 !important;
}
.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  color: #000 !important;
  padding: 0px 8px !important;
  font-size: 16px;
  display: inline-block;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #0076E2;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}
.navbar-light .navbar-nav .nav-link svg{
  display: none;
}
.main{
  background-image: url('../src/Assets/images/print.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.upload_document{
  padding: 40px 0px;
}
.upload_document .custom_row{
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.upload_document .custom_row::after{
  position: absolute;
  content: '';
  width: 800px;
  height: 800px;
  border-radius: 1030px;
  opacity: 0.3;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 118, 226, 0.25) 0%, rgba(10, 227, 255, 0.19) 100%);
  filter: blur(42px);
  right: -10%;
  top: 0;
}
.upload_document .image-right,.upload_document .upload_coument_right{
  width: 50%;
}
.upload_coument_right h3{
  padding-top: 32px;
  padding-bottom: 24px;
  color: #00265E;
}
.upload_document .image-right{
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 3;
}
.upload_document .image-right::after{
  background-image: url('../src/Assets/images/mobaile-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 450px;
  width: 680px;
  content: '';
  left: 0;
  z-index: -1;
}
.upload_document .image-right img{
  transition: all ease-in-out 0.3s;
}
.upload_document .image-right:hover img{
  transform: rotateZ(-4deg);
}
.blue_button{
  padding: 8px 16px;
  border: 0;
  color: #434343;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 600;
  transition: all ease-in-out 0.3s;
  border: 1px solid #032F70;
}
.blue_button:hover{
  background: #fff;
  color: #0076e2;
  border: 1px solid #0076e2;
}
.upload_document .custom_row{
  padding: 58px 0px;
  background-color: #fff;
  border-radius: 48px;
}
.upload_document .bottom-row{
  padding-top: 32px;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.upload_document .bottom-row .printing{
  border-radius: 48px;
  background-color: #fff;
  padding: 48px 32px 0px 32px;
  width: 48%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.upload_document .bottom-row .printing .upload_coument_right{
  width: 100%;
}
.bottom-row .print-img img{
  text-align: left;
  position: relative;
  left: -30px;
  width: 100%;
}
.more{
  padding: 80px 0px;
  background-color: #fff;
}
.more .container{
  display: flex;
  gap: 130px;
  align-items: center;
}
.more .blue_button,.slider-sec .blue_button{
  border: 1px solid #032F70B2;
  background-color: #fff;
  color: #434343;
}
.more .blue_button:hover,.slider-sec .blue_button:hover{
  background-color: rgba(0, 118, 226, 0.7);
  color: #fff;
}
.more .upload_coument_right .upload-btn.fill{
  border-radius: 50px;
  transition: all ease-in-out 0.3s;
}
.more .upload_coument_right .upload-btn.unfill{
  border-radius: 50px;
  background: #fff;
  border: 1px solid #858585;
  padding: 16px 32px;
  transition: all ease-in-out 0.3s;
}
.more .upload_coument_right .upload-btn.unfill span{
  color: #858585;
}
.more .btn-block{
  display: flex;
  align-items: center;
  gap: 16px;
}
.more .upload_coument_right .upload-btn.fill:hover,
.more .upload_coument_right .upload-btn.unfill:hover{
  transform: translateY(-5px);
}
.more .more-img{
  position: relative;
}
.more .more-img::after,
.more .more-img::before{
  position: absolute;
  content: '';
  background-image: url('../src/Assets/images/dot.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 88px;
  height: 20px;
  left: -17px;
  top: 35px;
  animation: mymove 5s infinite;

}

/* Animated part */
@keyframes mymove {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

/* animated part over */

.more .more-img::before{
  top: initial;
  bottom: 40px;
  left: initial;
  right: -10px;
}
.grey-bg{
  background-color: #f4f4f4;
}
.more.grey-bg .more-img::before{
  display: none;
}
.slider-sec .slider-main{
  display: flex;
  gap: 40px;
  justify-content: flex-start;
}
.slider-sec{
  padding: 20px 0px;
}
.slider-sec .slider-one{
  padding-top: 60px;
}
.slider-sec .slider-block{
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.slider-sec .slider-block img{
  height: 120px;
  width: 120px;
  border-radius: 24px;
}
.slider-sec .h3{
  margin-top: 30px;
  margin-bottom: 50px;
}
.slider-sec .slick-slider  .slick-arrow{
  background-color: #F4F4F4;
  position: absolute;
  top: -70px;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.slider-sec .slick-slider .slick-arrow.slick-prev::before,
.slider-sec .slick-slider .slick-arrow.slick-next::before{
  color: #8A8A8A;
}
.slider-sec .slick-slider .slick-arrow.slick-prev::before,
.slider-sec .slick-slider .slick-arrow.slick-next::before{
  content: '';
  color: transparent;
  background-image: url('../src/Assets/images/prev.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 50px;
  display: inline-block;
}
.slider-sec .slick-slider .slick-arrow.slick-next::before{
  background-image: url('../src/Assets/images/next.svg');
}
.slider-sec .slick-slider .slick-arrow.slick-prev{
  left: inherit;
  right: 60px;
}
.slider-sec .slick-slider{
  position: relative;
}
.slider-top .container{
  border-top: 1px solid #B8B8B8;
}
footer{
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #f4f4f4;
}
footer p{
  margin: 0px !important;
}
.banner-section .banner-image{
  position: relative;
}
.banner-section .triangle_one,
.banner-section .triangle_two{
  position: absolute;
  animation: mymoveteiangle 3s infinite;
}
@keyframes mymoveteiangle {
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}
.banner-section .triangle_one{
  height: 82px !important;
  width: 82px !important;
  right: 140px;
}
.banner-section .triangle_two{
  height: 46px !important;
  width: 46px !important;
  bottom: 60px;
  left: 0;
}
.more .more-img{
  position: relative;
  width: 50%;
  text-align: center;

}
.more .more-img .more_mobaile{
  transition: all ease-in-out 0.3s;
}
.more .more-img:hover .more_mobaile{
  transform: rotateZ(-4deg);
}
.more .upload_coument_right{
  width: 50%;
}
.more .more-img .more_mobaile_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
  animation: mycircle 5s infinite;
}
@keyframes mycircle {
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}
.more .more-img .more_mobaile{
  z-index: 3;
  position: relative;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.wrapper{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 16px;
}
.coupon-sec h3,
.coupon-sec span,
.coupon-sec .form-sec .grop label,
.coupon-sec .form-sec .amount h4{
    color: #000;
    font-family: "Alliance No.1";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.54px;
    text-transform: capitalize;
    margin: 0 !important;
}
.coupon-sec .form-sec .amount h4{
    font-size: 48px;
}
.coupon-sec span{
    color: #333;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding-top: 8px;
    display: inline-block;
}
.coupon-sec p,
.amout-block a{
    margin: 0 !important;
    color: #000;
    font-family: "Alliance No.1";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.27px;
    text-transform: capitalize;
    text-align: left;
}
.coupon-sec .form-sec{
    padding-top: 32px;
}
.coupon-sec .form-sec .grop input,
.coupon-sec .form-control{
    border-radius: 8px;
    border: 2px solid #D9D9D9;
    padding: 16px;
    color: #000;
    font-family: "Alliance No.1";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.27px;
}
.coupon-sec .form-control:focus{
  box-shadow: none !important;
}
.coupon-sec .form-sec .css-16xfy0z-control{
  min-height: 57px;
}
.coupon-sec .form-sec .grop input::placeholder{
    color: #000;
    font-family: "Alliance No.1";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.27px;
    text-transform: capitalize;
}
.coupon-sec .form-sec .grop span,
span b{
    color: #9C9C9C;
    font-family: "Alliance No.1";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.21px;
    text-transform: capitalize;
}
span b{
    color: #0076E2;
    font-weight: 700;
}
.form-btn{
    height: 50px;
    border-radius: 50px;
    background: #032F70;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: "Alliance No.1";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.27px;
    text-transform: capitalize;
    border: 0;
    min-width: 121px;
    justify-content: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    text-decoration: none !important;
}
.form-btn:hover{
    transform: translateY(-5px);
}
.coupon-sec .form-sec .grop label{
    display: block;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    padding-bottom: 10px;
}
.coupon-sec .form-sec .grop{
    max-width: 487px;
    display: flex;
    flex-direction: column;
}
.coupon-sec .form-sec .grop span a {
    color: rgba(13,110,253);
    text-decoration: none;
}
.coupon-sec .form-sec .form-btn{
    margin-top: 30px;
}
.coupon-sec{
    padding-top: 50px;
}
.coupon-sec .form-sec .amount{
    padding-bottom: 30px;
}
.coupon-sec .form-sec .card-img{
    padding-top: 12px;
}
.card-img img {
    width: 70px;
}
.unfill{
    padding: 16px 0px;
    background: #fff;
    border: 2px solid #D9D9D9;
    color: #808080;
}
.form-btn-block{
    display: flex;
    gap: 16px;
}
.coupon-sec .form-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
 .coupon-sec .form-file + label {
    color: #000;
    cursor: pointer;
    background-color: white;
    display: inline-block;
  }
  .coupon-sec .form-file + label span {
    font-family: inherit;
    padding-top: 0;
  }
  .coupon-sec .form-file + label {
    max-width: 80%;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 8px 12px;
    border-radius: 8px;
    border: 2px solid #032F70;
    margin-top: 8px;
  }
  .coupon-sec .form-file:focus + label,  .coupon-sec .form-file.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  .coupon-sec  .form-file + label {
    color: #0D5D87;
  }
  .coupon-sec .form-file:focus + label, .coupon-sec .form-file.has-focus + label,
  .coupon-sec .form-file + label:hover {
    color: #148dcd;
  }
  .coupon-sec .document{
    padding: 0px 0px 15px 0px;
  }
  .center{
    text-align: center;
  }
  .congratulation span{
    display: block;
  }
  .congratulation .form-btn-block{
    justify-content: center;
    padding-top: 6px;
  }
  .congratulation .form-btn-block .form-btn{
    min-width: 150px;
  }
  .block-element{
    padding-bottom: 30px;
  }
  .amout-block .amount-text{
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
border: 2px solid #D9D9D9;
cursor: pointer;
padding: 12px 20px;
  }
.block-element p{
margin-bottom: 10px !important;
}
.amout-block{
display: flex;
flex-wrap: wrap;
gap: 16px;
}
.amout-block .amount-text.active{
border: 2px solid #032F70;
}

.more-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid gray;
}

.back-btn {
    border-radius: 50px;
    background: #032F70;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #FFF;
    border: 0;
    min-width: 121px;
    padding: 10px;
}

.show-pdf-action {
    display: flex;
    gap: 10px;
}

.show-pdf {
    height: 220px;
    width: 138px;
    overflow: hidden;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    object-fit: fill;
    padding: 8px;
    position: relative;
    margin-bottom: 20px;
}

.show-pdf .react-pdf__Page__canvas {
    display: block !important;
     user-select: none !important;
     width: 120px !important;
     height: 140px !important;
     border: 1px solid #d3d3d3;
     border-radius: 10px;
}

.img-wrapper {
    width: 120px;
    height: 140px;
    object-fit: contain;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    padding: 10px;
}

.img-wrapper img {
  max-width: 100%;
  max-height: 100%;
}

.button-pdf-cancel {
    position: absolute;
    bottom: 8px;
}

.file-name {
    font-size: 15px !important;
    position: absolute;
    bottom: 48px;
    display: inline-block;
    width: 122px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-pdf__Page__textContent > span {
    display: none !important;
}

.payment-detail {
    border: 1px solid;
    border-radius: 15px;
    padding: 10px;
}

.select-option {
    margin-right: 10px;
    width: 50%;
}

.loadWrapper {
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 99999;
}
.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3d3e3f; /* gray */
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.show-file {
    height: 220px;
    width: 40%;
    overflow: hidden;
    border: 1px solid #000000;
    border-radius: 10px;
    object-fit: fill;
    padding: 8px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 5px 5px;
}

.show-file .react-pdf__Page__canvas {
    display: block !important;
     user-select: none !important;
     width: 225px !important;
     height: 194px !important;
}
.img-wrap-file {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 243px;
    height: 33vh;
    padding: 10px;
}
.show-document {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.show-document div {
    flex: 0 0 calc(50% - 20px);
    margin: 10px;
}

.img-search {
  width: 16px;
  position: absolute;
  top: 45px;
  right: 145px;
}

.search-input {
    padding: 6px 20px 6px 40px !important;
}

.back-button {
    background: none;
    border: none;
}
.back-button img {
    position: relative;
    bottom: 3px;
    margin-right: 8px;
    transform: rotate(180deg);
}
.navbar .navbar-nav {
  width: 100%;
  justify-content: end;
  align-items: center;
}
.navbar-nav .nav-item .search-input:focus{
  box-shadow: none;
  outline: none;
}
.search-box{
  margin-left: 140px;
}
.search-box .form-control {
  padding: 10px 10px 10px 30px !important;
  border-radius: 8px;
  max-width: 155px;
  background: #E2E2E2;
}
.more-navbar .wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.img-wrap-file img {
    height: 200px;
    width: 250px
}
.nav-tabs {
    display: flex;
    justify-content: space-between;
    border: 0 !important;
}
.tab-item {
    width: 33%;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}

.input-text {
    max-width: 487px;
}

.top-up-details {
  display: flex;
  flex-direction: column;
  margin: auto;
  background: linear-gradient(90deg, #0076E2 0%, #0AE3FF 139.1%);
  color: white;
  padding: 30px;
  border: 2px solid white;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.top_up_section{
  display: flex;
}
.detail {
  display: flex;
  justify-content: space-between;
}
.detail span{
  color: white;
  padding-top: 3px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 30px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.custom-table th {
  background: linear-gradient(91deg, #0076e2 -8.04%, #0ae3ff 124.91%), #fff;
  text-align: center;
  padding: 10px;
  color: #fff;
}
.search-group {
    display: flex;
    justify-content: space-between;
}
.search-code-box {
    display: flex;
    justify-content: end;
}
.search-code-box .form-control {
   max-width: 215px !important;
 }
.img-code-search {
    top: 212px;
    right: 222px;
    width: 16px;
    position: absolute;
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  border-bottom: 1px solid #000;

    background: linear-gradient(91deg, #000 -8.04%, #000 124.91%), #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link.active{
  border-bottom: 1px solid #1dacf0;
  background: #032F70;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.coupon-sec .upload-btn{
  color: #fff !important;
  width: 182px;
  border: 0;
  margin: 0;
  transition: all 0.4s;
  height: 55px;
}
.coupon-sec .search-code-box{
  margin: 0;
  position: relative;

}
.coupon-sec .search-code-box .form-control{
  max-width: 100% !important;
  background-color: #fff;
  border-color: #05a6ef;
}
.coupon-sec  .img-code-search{
  top: 20px;
  left: 10px;
}

.coupon-sec .search-code-box .upload-btn{
  display: none;
}

.navbar-nav .nav-item .nav-active{
  font-weight: bold;
  background: #032F70;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title-header {
    display: flex;
    align-items: center;
    color: #032F70;
    font-size: 15px;
    padding-left: 10px;
    font-weight: 700;
}
.search-box-responsive{
  display: none;
}