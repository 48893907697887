  .top-header .title span {
    color: #fff;
    font-size: 20px !important;
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    max-width: 393px;
    margin: 0 auto;
    display: block;
  }
  
  @media (max-width: 767px) {
    .top-header .title span {
      text-align: center;
      font-size: 14px !important;
      max-width: 230px;
    }
  }
  
  .option_block_custom-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    border: 5px solid transparent;
    border: 2px solid #032F70;
    border-radius: 24px;
    padding: 0px 50px;
    row-gap: 16px;
    width: 100%;
    height: auto;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  @media (max-width: 767px) {
    .option_block_custom-summary {
      width: auto;
      max-width: 100%;
      padding: 16px;
      margin: 0;
    }
  }
  
  .option_block_custom-summary .legend {
    font-weight: 600;
    font-size: 32px;
    color: rgb(0, 0, 0);
    width: fit-content;
    background-color: white;
    margin-left: 15px;
    padding-inline: 5px;
    text-align: center;
    height: 100%;
  }
  
  .option_block_custom-summary .column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  }
  
  .option_block_custom-summary .column span {
    color: #727e8c;
    font-size: 24px;
    font-weight: 700;
  }
  
  @media (max-width: 767px) {
    .option_block_custom-summary .column span {
      font-size: 16px;
    }
  }
  
  .option_block_custom-summary .column .left-text {
    color: black;
  }
  
  .custom_btn-summary {
    text-align: center;
  }
  
  .custom_btn-summary .white_btn {
    width: 500px;
    height: auto;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 8px;
    font-size: 28px;
    margin-top: 100px;
    background: #fff;
    color: rgb(22, 155, 225);
  }
  
  .custom_btn-summary .white_btn:hover {
    background-color: rgb(22, 155, 225);
    color: #fff;
  }
  .upload-btn{
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }
  
  @media (max-width: 1023px) {
    .custom_btn-summary .white_btn {
      margin-top: 20px;
    }
  }
  
  @media (max-width: 767px) {
    .custom_btn-summary .white_btn {
      font-size: 18px;
      width: auto;
      line-height: 35px;
      padding: 10px;
    }
  }
  
  .payment-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  @media (max-width: 767px) {
    .payment-type {
      flex-direction: column;
    }
  }
  
  .payment-type .flex-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin: 40px;
    height: 450px;
  }
  
  .payment-type .flex-box .image-container .payment-image {
    height: 80px;
    width: auto;
  }
  
  .payment-type .flex-box .text-container {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .payment-type .flex-box .text-container h4,
  .payment-type .flex-box .text-container h1 {
    margin-block: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .payment-type .flex-box .text-container .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    align-items: left;
  }
  
  .payment-type .flex-box .text-container .input-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    color: black;
  }
  
  .payment-type .flex-box .text-container .coupon-applied {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    background-color: rgb(228, 244, 218);
    color: green;
    padding-inline: 10px;
    padding-block: 5px;
  }
  
  .payment-type .flex-box .text-container .coupon-applied h3 {
    color: rgb(0, 117, 0);
  }
  
  .payment-type .flex-box .text-container .coupon-applied h4 {
    color: green;
  }
  
  .payment-type .flex-box .payment-btn {
    background: linear-gradient(white, white) padding-box padding-box,
      linear-gradient(270deg, rgb(0, 118, 226), rgb(10, 227, 255))
        border-box border-box;
    color: white;
  }
  
  .payment-type .flex-box .payment-btn .white_btn {
    border: none;
  }
  
  @media (max-width: 767px) {
    .payment-type span {
      display: none;
    }
  }
  
  .payment-type span {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    width: 90px;
    text-align: center;
  }
  
  .payment-type span:before {
    border-left: 1px solid black;
    content: "";
    display: block;
    height: 85px;
    top: 40px;
    width: 5px;
    z-index: 0;
  }
  
  .payment-type span:after {
    border-left: 1px solid black;
    content: "";
    display: block;
    height: 85px;
    left: 32px;
    top: 140px;
    width: 5px;
    z-index: 0;
  }
  