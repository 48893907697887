@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Medium.eot');
    src: url('AllianceNo1-Medium.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Medium.woff2') format('woff2'),
        url('AllianceNo1-Medium.woff') format('woff'),
        url('AllianceNo1-Medium.ttf') format('truetype'),
        url('AllianceNo1-Medium.svg#AllianceNo.1-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;             
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-ExtraBold.eot');
    src: url('AllianceNo1-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-ExtraBold.woff2') format('woff2'),
        url('AllianceNo1-ExtraBold.woff') format('woff'),
        url('AllianceNo1-ExtraBold.ttf') format('truetype'),
        url('AllianceNo1-ExtraBold.svg#AllianceNo.1-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Regular.eot');
    src: url('AllianceNo1-Regular.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Regular.woff2') format('woff2'),
        url('AllianceNo1-Regular.woff') format('woff'),
        url('AllianceNo1-Regular.ttf') format('truetype'),
        url('AllianceNo1-Regular.svg#AllianceNo.1-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-SemiBold.eot');
    src: url('AllianceNo1-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-SemiBold.woff2') format('woff2'),
        url('AllianceNo1-SemiBold.woff') format('woff'),
        url('AllianceNo1-SemiBold.ttf') format('truetype'),
        url('AllianceNo1-SemiBold.svg#AllianceNo.1-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Black.eot');
    src: url('AllianceNo1-Black.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Black.woff2') format('woff2'),
        url('AllianceNo1-Black.woff') format('woff'),
        url('AllianceNo1-Black.ttf') format('truetype'),
        url('AllianceNo1-Black.svg#AllianceNo.1-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Light.eot');
    src: url('AllianceNo1-Light.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Light.woff2') format('woff2'),
        url('AllianceNo1-Light.woff') format('woff'),
        url('AllianceNo1-Light.ttf') format('truetype'),
        url('AllianceNo1-Light.svg#AllianceNo.1-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Bold.eot');
    src: url('AllianceNo1-Bold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Bold.woff2') format('woff2'),
        url('AllianceNo1-Bold.woff') format('woff'),
        url('AllianceNo1-Bold.ttf') format('truetype'),
        url('AllianceNo1-Bold.svg#AllianceNo.1-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

