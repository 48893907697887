/* Responsive part start */

@media (max-width: 1279px) {
    .navbar{
        padding: 10px 0px !important;
    }
    .more .container{
      gap: 30px;
    }
    .more .more-img .more_mobaile_bg{
      width: 450px;
      height: auto;
    }
    .more.grey-bg .more-img::after{
      width: 65px;
      left: -7px 
    }
    footer{
      padding-top: 30px;
      padding-bottom: 20px;
    }
    .banner-section{
      padding-top: 150px;
      padding-bottom: 100px;
      height: auto;
    }
    .h1{
      font-size: 60px !important;
    }
    .search-box {
      margin-left: 35px;
    }
    .upload_document .custom_row::after{
      height: 600px;
    width: 600px;
    }
  }
  @media (max-width: 1023px) {
      .h1{
        font-size: 50px !important;
      }
      .banner-section .banner-left .span{
        font-size: 28px !important;
      }
      .upload_coument_right h3{
        padding: 15px 0px;
      }
      .h3{
        font-size: 28px !important;
      }
      .upload_document .image-right::after{
        width: 400px;
        height: 300px;
      }
      .upload_document .bottom-row .printing{
        padding: 30px 16px 0px 16px;
        width: 47%;
      }
      .bottom-row .print-img img{
        width: 100%;
      }
      .more .upload_coument_right,.more .more-img{
        width: 50%;
      }
      .more .more-img img{
        width: 100%;
      }
      .more .more-img::after{
        top: 20px;
        left: -25px;
      }
      .more .more-img::before{
        bottom: 25px;
      }
      .more .more-img::before,
      .more .more-img::after{
        width: 60px;
      }
      .more.grey-bg .more-img::after{
        top: -25px;
      }
      .h2{
        font-size: 50px !important;
      }
      .slider-sec .h3{
        margin: 30px 0px 50px 0px;
      }
      footer{
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .banner-section .triangle_one{
        top: -70px;
        right: 0;
      }
      .more .more-img .more_mobaile{
        width: 300px;
      }
      .search-box {
        margin-left: 10px;
      }
      .navbar-nav .nav-item a{
        font-size: 11px;
        padding: 0px 5px !important;
      }
      .navbar-brand img{
        max-width: 50px;
      }
      .search-box label{
        font-size: 12px !important;
      }
      .img-search{
        right: 130px;
      }
      .upload_document .custom_row::after{
        height: 500px;
        width: 500px;
      }

  }
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
    .banner-section .container{
      flex-direction: column-reverse;
    }
    .banner-section .banner-left,.banner-section .banner-image{
      width: 100%;
    }
    .banner-section{
      height: auto;
      padding-top: 100px;
    }
    .navbar{
      padding: 10px 0px !important;
    }
    .navbar.navbar_show{
      background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(6px);
    transition: all 0.4s;
    }
    .navbar_show .navbar-toggler span{
      background-color: #fff !important;
    }
    .navbar-nav .nav-item a{
      font-size: 20px;
      color: #ffffff96 !important;
      font-weight: 500;
    }
    .navbar-nav .nav-item a.active{
      font-weight: 800;
    }
    .search-box label{
      color: #fff;
      font-weight: 400 !important;
    }
    .navbar .custom-logo img{
      width: 45px;
      height: auto;
    }
    .navbar .navbar-collapse{
      height: 100vh !important;
      transition: all ease-in-out 0.3s;
    }
    .search-box .form-control,
    .search-box  .form-control:focus{
      background: transparent !important;
      color: #000 !important;
    }
    .search-box .form-control::placeholder{
      color: #fff;
    }
    .img-search {
      filter: invert(100%);
    }
    .navbar.sticky .container{
      background-color: transparent;
    }
    .h1{
      font-size: 40px !important;
      text-align: center;
    }
    .banner-section .banner-left .span {
      font-size: 20px !important;
      line-height: 25px;
      text-align: center;
      display: block;
    }
    .upload-btn{
      padding: 15px;
      margin: 0 auto;
      transition: all ease-in-out 0.3s;
    }
    
    p{
      text-align: center;
      font-size: 14px !important;
    }
    .upload-btn .upload-text{
      font-size: 15px;
    }
    .upload_document .custom_row{
      flex-direction: column;
    }
    .upload_document .image-right, .upload_document .upload_coument_right{
      width: 100%;
    }
    .upload_coument_right{
      text-align: center;
    }
    .upload_document .custom_row{
      padding: 30px 0px;
    }
    .upload_document .bottom-row .printing{
      padding: 20px 16px 0px 16px;
      width: 100%;
    }
    .more .container{
      flex-direction: column;
    }
    .more .upload_coument_right, .more .more-img{
      width: 100%;
    }
    .more{
      padding: 40px 0px;
    }
    .more .btn-block{
      gap: 10px;
    }
    .more .more-img::before,
      .more .more-img::after{
        display: none;
      }
      .more.grey-bg .container {
        flex-direction: column-reverse;
    }
    .slider-sec .slick-slider .slick-arrow{
      top: -10px;
      height: 40px;
      width: 40px;
    }
    .slider-sec .slick-slider .slick-arrow.slick-prev{
      right: 50px;
    }
    .h2{
      font-size: 35px !important;
    }
    .slider-sec {
      padding: 40px 0px;
    }
    .slider-sec .h3{
      text-align: center;
    }
    .slider-sec .slider-block{
      align-items: center;
      justify-content: center;
    }
    .banner-section .triangle_one{
      top: -20px;
      height: 50px !important;
      width: 50px !important;
    }
    .more .more-img .more_mobaile_bg{
      top: 10%;
    }
    .more .more-img .more_mobaile{
      width: 250px;
    }
    .top_up_section{
      flex-direction: column-reverse;
      gap: 50px
    }
    .coupon-sec .wrapper{
      padding: 0px;
    }
  }

@media only screen and (max-width: 767px) {
    .coupon-sec h3{
        font-size: 25px;
    }
    .coupon-sec{
        padding-top: 30px;
    }
    .coupon-sec .form-sec .amount h4{
        font-size: 32px;
    }
    .congratulation .congo-img img{
        width: 160px;
        height: auto;
    }
    .navbar .navbar-nav{
      height: 100%;
      justify-content: center;
      gap: 15px;
      padding-bottom: 70px;
    }
    .search-box{
      margin-left: 0;
    }
    .upload_document .custom_row::after{
      bottom: 0;
      top: initial;
      right: 0;
      height: 300px;
      width: 300px;
    }
    .more-navbar .img-fluid{
      max-width: 120px;
    }
    .coupon-sec .form-sec .grop .d-flex{
      flex-direction: column;
      gap: 20px;
    }
    .more-navbar{
      padding: 0px 0px 10px 0px;
    }
    .more-navbar .wrapper{
      padding: 0;
    }
   .coupon-sec .select-option{
      width: 100%;
    }
    .slider-sec .slick-slider .slick-arrow.slick-prev::before, .slider-sec .slick-slider .slick-arrow.slick-next::before{
      height: 30px;
      width: 30px;
    }
    .slider-sec .slick-slider{
      padding-top: 20px;
    }
    .nav-tabs{
      flex-direction: column;
    }
    .tab-item{
      width: 100%;
    }
    .coupon-sec .upload-btn{
      width: auto;
    }
    .coupon-sec .search-group{
      gap: 20px;
      flex-direction: column-reverse;
    }
    .coupon-sec .search-group > .upload-btn{
      width: 120px;
    margin: 0 auto;
    }
    
    .table-container{
      overflow: hidden;
    }
    .custom-table{
      overflow: auto;
    }
    
.coupon-sec .search-code-box .upload-btn{
  display: block;
}
.mt-5.prepaid{
  margin-top: 20px !important;
}

.coupon-sec span,.coupon-sec .form-sec .grop label{
  font-size: 13px;
}
.coupon-sec .form-sec{
  padding-top: 20px;
}
.coupon-sec .form-sec .grop input{
  padding: 14px;
  font-size: 13px;
}
.coupon-sec .form-sec .css-16xfy0z-control {
  min-height: 44px;
}
.coupon-sec .form-sec .grop input::placeholder{
  font-size: 13px;
}
.amout-block .amount-text{
  padding: 11px 17px;
  font-size: 13px;
}
.navbar-nav .nav-item .nav-active {
        background: transparent;
        color: #fff !important;
        -webkit-text-fill-color: #fff;
    }
    .title-header{
      font-size: 10px;
    }
    .search-box-responsive{
      display: block;
      position: relative;
      width: 34%;
    }
    .navbar-toggler{
      padding: 0 !important;
    }
    .search-box-responsive img{
      filter: none;
      left: 8px;
      top: 10px;
      position: absolute ;
      width: 13px;
      height: 13px;
    }
    .search-box-responsive .search-input{
      padding-left: 25px !important;
      font-size: 12px;
      list-style: 12px;
    }
    .search-box-responsive .search-input::placeholder{
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
    }
    .navbar_show .custom-logo,
    .navbar_show  .search-box-responsive{
      opacity: 0;
      visibility: hidden;
    }
}

/* Responsive part End */
