body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
  .container-body {
    max-width: 400px;
    margin: 50px auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .container-body h1 {
    text-align: center;
    color: #333;
  }
  
  .container-body form {
    display: flex;
    flex-direction: column;
  }
  
  .container-body form label {
    margin-bottom: 5px;
    color: #666;
  }
  
  .container-body form input[type="text"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .container-body form input[type="text"]:focus {
    border-color: #4CAF50;
  }
  
  .container-body form input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .container-body form input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .container-body form .text-danger {
    color: #ff0000;
    margin-top: 5px;
  }
  